/* DEBUG: we don't use css files for this project... */
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
.pagination > li {
    background-color: #e5e5e5;
    padding: 0;
}
  .pagination > .active {
    background-color: #8a8a8a ;
    border-color: #e5e5e5 ;
    color: #000;
  }

  .pagination > li > a{
    border: 1px solid #e5e5e5 ;
    padding: 0px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #8a8a8a ;
    border-color: #8a8a8a;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #000
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }