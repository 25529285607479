body {
  margin: 0;
  height: fit-content;
  /* DEBUG: more fallback font could be added */
  font-family: "Century Gothic", "sans-serif" !important;
}

a {
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  margin: 0;
}

.text-center {
  text-align: center;
}

#portal_menuIcon {
  z-index: 5 !important;
}
